import { Link } from "gatsby"
import React from "react"
import CustomMarkdown from "../../utils/customMarkdown"

//import 'uikit/dist/css/uikit.css'
import "./accordion.scss"

const Accordion = ({ title, contents, enableSnippet, topPadding, bottomPadding }) => {

    let seoData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: contents.map((item, index) => {
            return {
                "@type": "Question",
                name: item.Question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: item.Answer
                }
            }
        })
    }

    return (
        <>
            <div className={`faq-container ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''} lateral-padding`}>
                <div className="uk-card uk-card-muted uk-width-1-2@m uk-align-center">

                    {
                        enableSnippet == true ? <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(seoData) }} /> : ""
                    }


                    <div>
                        <h2 className="uk-text-center">{title}</h2>
                    </div>
                    <div data-uk-accordion className=" faq-accordion">
                        {contents.map((item, index) => {
                            return (
                               
                                    <div key={index} className="list">
                                        <a className="uk-accordion-title faq-question" href="#0">
                                            {item.Question}
                                        </a>
                                        <div className="uk-accordion-content faq-answer">
                                            <CustomMarkdown children={item.Answer} />
                                            {((item.URL)?.length > 0) ?
                                                <Link to={item.URL} className="accordion-answer-read-more">Learn more about this</Link>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accordion